const productsImageManip = "f_auto,q_auto";

const collectionRelease1 = [
  {
    id: "fw21-classics",
    name: "Fall/Winter 2021 Classics",
    img: "https://res.cloudinary.com/habitatsartorial/image/upload/v1629236884/Summer%202021%20Photos/DSC08620_is6dwa.jpg",
    description: "Description 1",
    altLink: "/content/fw21-classics-lookbook",
    products: [
      {
        id: 0,
        price: 19,
        type: "Tee",
        img: `https://res.cloudinary.com/habitatsartorial/image/upload/${productsImageManip}/v1629324137/Fall%20Winter%202021%20Classics%20Product%20Shots/SS%20-%200/DSC08500_2_u1dt0e.jpg`,
        images: [
          "https://res.cloudinary.com/habitatsartorial/image/upload/v1629324137/Fall%20Winter%202021%20Classics%20Product%20Shots/SS%20-%200/DSC08500_2_u1dt0e.jpg",
          "https://res.cloudinary.com/habitatsartorial/image/upload/v1629324138/Fall%20Winter%202021%20Classics%20Product%20Shots/SS%20-%200/DSC08807_j8lcm9.jpg",
          "https://res.cloudinary.com/habitatsartorial/image/upload/v1629324140/Fall%20Winter%202021%20Classics%20Product%20Shots/SS%20-%200/DSC08757_qdmmhb.jpg",
          "https://res.cloudinary.com/habitatsartorial/image/upload/v1629324142/Fall%20Winter%202021%20Classics%20Product%20Shots/SS%20-%200/DSC08936_2_izcls5.jpg",
        ],
        description: [
          '"HA317AT" Logo at center of chest',
          "100% 6.1oz ringspun cotton",
          "Preshrunk, soft-washed, garment dyed fabric",
          "Color: Faded khaki green",
        ],
        name: '"HA317AT" Tee',
        disabled: false,
      },

      {
        id: 1,
        price: 24,
        type: "LS Tee",
        img: `https://res.cloudinary.com/habitatsartorial/image/upload/${productsImageManip}/v1629324480/Fall%20Winter%202021%20Classics%20Product%20Shots/LS%20-%201/DSC08800_mcy0aw.jpg`,
        images: [
          "https://res.cloudinary.com/habitatsartorial/image/upload/v1629324480/Fall%20Winter%202021%20Classics%20Product%20Shots/LS%20-%201/DSC08800_mcy0aw.jpg",
          "https://res.cloudinary.com/habitatsartorial/image/upload/v1629324483/Fall%20Winter%202021%20Classics%20Product%20Shots/LS%20-%201/DSC08938_2_xja236.jpg",
        ],
        description: [
          '"HA317AT" Logo at center of chest',
          "100% 6.1oz ringspun cotton",
          "Preshrunk, soft-washed, garment dyed fabric",
          "Color: Faded khaki green",
        ],
        name: '"HA317AT" Longsleeve Tee',
        disabled: false,
      },

      {
        id: 2,
        price: 26,
        type: "Mockneck Tee",
        img: `https://res.cloudinary.com/habitatsartorial/image/upload/${productsImageManip}/v1629325542/Fall%20Winter%202021%20Classics%20Product%20Shots/3%20-%20MN/DSC08533_2_ix4l9s.jpg`,
        images: [
          "https://res.cloudinary.com/habitatsartorial/image/upload/v1629325542/Fall%20Winter%202021%20Classics%20Product%20Shots/3%20-%20MN/DSC08533_2_ix4l9s.jpg",
          "https://res.cloudinary.com/habitatsartorial/image/upload/v1629325547/Fall%20Winter%202021%20Classics%20Product%20Shots/3%20-%20MN/DSC08994_2_bc5pcb.jpg",
          "https://res.cloudinary.com/habitatsartorial/image/upload/v1629325546/Fall%20Winter%202021%20Classics%20Product%20Shots/3%20-%20MN/DSC08990_2_fnlyrm.jpg",
        ],
        description: [
          '"HA317AT" Logo at right-center of collar',
          "Rotary logo at bottom-left of back",
          "100% 6.1oz ringspun cotton",
          "Color: White",
        ],
        name: '"HA317AT" Mockneck Tee',
        disabled: true,
      },

      {
        id: 3,
        price: 36,
        type: "Cropped Hoodie",
        img: `https://res.cloudinary.com/habitatsartorial/image/upload/${productsImageManip}/v1629324723/Fall%20Winter%202021%20Classics%20Product%20Shots/CH%20-%202/DSC08719_fpdael.jpg`,
        images: [
          "https://res.cloudinary.com/habitatsartorial/image/upload/v1629324723/Fall%20Winter%202021%20Classics%20Product%20Shots/CH%20-%202/DSC08719_fpdael.jpg",
          "https://res.cloudinary.com/habitatsartorial/image/upload/v1629324720/Fall%20Winter%202021%20Classics%20Product%20Shots/CH%20-%202/DSC08628_miltm1.jpg",
          "https://res.cloudinary.com/habitatsartorial/image/upload/v1629324723/Fall%20Winter%202021%20Classics%20Product%20Shots/CH%20-%202/DSC08732_ctt4wa.jpg",
          "https://res.cloudinary.com/habitatsartorial/image/upload/v1629324723/Fall%20Winter%202021%20Classics%20Product%20Shots/CH%20-%202/DSC08943_2_giyn3r.jpg",
        ],
        description: [
          '"100%" logo at center of chest',
          '"Habitat Sartorial" logo at top of back',
          "7 oz. fabric: 52% combed ringspun cotton, 48% poly fleece",
          "Drop-shoulder, raw cut hem",
          "Color: Black",
        ],
        name: '"100%" Cropped Hooded Sweatshirt',
        disabled: false,
      },

      {
        id: 4,
        price: 36,
        type: "Hoodie",
        img: `https://res.cloudinary.com/habitatsartorial/image/upload/${productsImageManip}/v1629325109/Fall%20Winter%202021%20Classics%20Product%20Shots/H%20-%203/DSC08502_cehedf.jpg`,
        images: [
          "https://res.cloudinary.com/habitatsartorial/image/upload/v1629325109/Fall%20Winter%202021%20Classics%20Product%20Shots/H%20-%203/DSC08502_cehedf.jpg",
          "https://res.cloudinary.com/habitatsartorial/image/upload/v1629325113/Fall%20Winter%202021%20Classics%20Product%20Shots/H%20-%203/DSC08943_2_hhdhnt.jpg",
          "https://res.cloudinary.com/habitatsartorial/image/upload/v1629325118/Fall%20Winter%202021%20Classics%20Product%20Shots/H%20-%203/DSC08953_2_egj3v1.jpg",
        ],
        description: [
          '"100%" logo at center of chest',
          '"Habitat Sartorial" logo at top of back',
          "7.2 oz. 100% ringspun cotton",
          "Raglan sleeves, kangaroo pocket",
          "Color: Black",
        ],
        name: '"100%" Hooded Sweatshirt',
        disabled: false,
      },

      {
        id: 5,
        price: 31,
        type: "Sweatshorts",
        img: `https://res.cloudinary.com/habitatsartorial/image/upload/${productsImageManip}/v1629325982/Fall%20Winter%202021%20Classics%20Product%20Shots/5%20-%20S/DSC08502_v2y4fz.jpg`,
        images: [
          "https://res.cloudinary.com/habitatsartorial/image/upload/v1629325982/Fall%20Winter%202021%20Classics%20Product%20Shots/5%20-%20S/DSC08502_v2y4fz.jpg",
          "https://res.cloudinary.com/habitatsartorial/image/upload/v1629325808/Fall%20Winter%202021%20Classics%20Product%20Shots/5%20-%20S/DSC08800_jaqdyp.jpg",
          "https://res.cloudinary.com/habitatsartorial/image/upload/v1629325808/Fall%20Winter%202021%20Classics%20Product%20Shots/5%20-%20S/DSC08757_vvwhc0.jpg",
          "https://res.cloudinary.com/habitatsartorial/image/upload/v1629325812/Fall%20Winter%202021%20Classics%20Product%20Shots/5%20-%20S/DSC08958_2_wzrpxs.jpg",
          "https://res.cloudinary.com/habitatsartorial/image/upload/v1629325813/Fall%20Winter%202021%20Classics%20Product%20Shots/5%20-%20S/DSC08963_2_wyfkqe.jpg",
        ],
        description: [
          '"HA317AT" logo across front',
          '"100%" logo on left of back',
          "5.5 inch inseam",
          "8.5 oz. fabric: 52% ringspun cotton, 48% poly fleece",
          "Elastic waistband with drawcord",
          "Back and side pockets",
          "Sewn eyelets",
          "Raw cut hem",
          "Color: Grey",
        ],
        name: '"HA317AT" Sweatshorts',
        disabled: false,
      },

      {
        id: 6,
        price: 36,
        type: "Sweatpants",
        img: `https://res.cloudinary.com/habitatsartorial/image/upload/${productsImageManip}/v1629326307/Fall%20Winter%202021%20Classics%20Product%20Shots/6%20-%20SP/DSC08706_2_qngolt.jpg`,
        images: [
          "https://res.cloudinary.com/habitatsartorial/image/upload/v1629326307/Fall%20Winter%202021%20Classics%20Product%20Shots/6%20-%20SP/DSC08706_2_qngolt.jpg",
          "https://res.cloudinary.com/habitatsartorial/image/upload/v1629326310/Fall%20Winter%202021%20Classics%20Product%20Shots/6%20-%20SP/DSC08807_il98z4.jpg",
          "https://res.cloudinary.com/habitatsartorial/image/upload/v1629326310/Fall%20Winter%202021%20Classics%20Product%20Shots/6%20-%20SP/DSC08628_f0qj42.jpg",
          "https://res.cloudinary.com/habitatsartorial/image/upload/v1629326306/Fall%20Winter%202021%20Classics%20Product%20Shots/6%20-%20SP/DSC08533_2_jrkogu.jpg",
          "https://res.cloudinary.com/habitatsartorial/image/upload/v1629326311/Fall%20Winter%202021%20Classics%20Product%20Shots/6%20-%20SP/DSC08732_t9huw2.jpg",
        ],
        description: [
          '"100%" logo on left of back',
          "Tapered fit, ankle cuffs",
          "8.5 oz. fabric: 52% ringspun cotton, 48% poly fleece",
          "Elastic waistband with drawcord",
          "Back and side pockets",
          "Sewn eyelets",
          "Color: Grey",
        ],
        name: '"100%" Sweatpants',
        disabled: true,
      },
    ],
  },
  {
    id: "s21-music",
    altLink: "/content/summer21-promo-video",
    name: "Summer 2021 Music Collection",
    img: "https://res.cloudinary.com/habitatsartorial/image/upload/v1629430012/Summer%202021%20Music%20Collection/thumb_notext_2_wc7zfz.png",
    description: "Description 2",
    products: [],
  },
];

const collectionRelease2 = [
  {
    id: "bifi-24",
    name: "Artist Apparel: By Indy, For Indy 2024",
    img: "https://res.cloudinary.com/habitatsartorial/image/upload/v1724345008/bifi24/Screenshot_2024-08-22_at_12.43.24_vspkj0.png",
    description: "Artist Apparel: By Indy, For Indy 2024.",
    altLink: "/shop/collections/bifi-24",
    products: [
      {
        id: 200,
        price: 24,
        type: "Tee",
        img: `https://res.cloudinary.com/habitatsartorial/image/upload/${productsImageManip}/v1724344932/bifi24/lineupandmerch_bifi24_hs._005_gka6en.jpg`,
        images: [
          "https://res.cloudinary.com/habitatsartorial/image/upload/v1724344932/bifi24/lineupandmerch_bifi24_hs._005_gka6en.jpg",
          "https://res.cloudinary.com/habitatsartorial/image/upload/v1724344932/bifi24/lineupandmerch_bifi24_hs._001_ovqghw.jpg",
          "https://res.cloudinary.com/habitatsartorial/image/upload/v1724344932/bifi24/lineupandmerch_bifi24_hs._002_q0r7vh.jpg",
          "https://res.cloudinary.com/habitatsartorial/image/upload/v1724344932/bifi24/lineupandmerch_bifi24_hs._003_kqbcxv.jpg",
          "https://res.cloudinary.com/habitatsartorial/image/upload/v1724344932/bifi24/lineupandmerch_bifi24_hs._004_ckxepf.jpg",
        ],
        description: [
          "One-Line Artist Logo across front of shirt",
          "Show Poster across back of shirt",
          "100% 6 oz American grown SUPIMA cotton",
          "Cut and sewn in Peru",
          "Standard fit",
          "Color: Natural",
          "",
          "Half the profits from sales of this tee will go to the Artist, and half to Horizon House Indy.",
          "",
        ],
        name: "BIFI24 Concert Apparel",
        disabled: false,
      },
    ],
  },

  {
    id: "bcfc-23",
    name: "Artist Apparel: By Chicago, For Chicago 2023",
    img: "https://res.cloudinary.com/habitatsartorial/image/upload/v1694108970/bcfc23/317habitat_xrvcta.jpg",
    description: "Artist Apparel: By Chicago, For Chicago 2023.",
    altLink: "/content/bcfc23-recap",
    products: [
      {
        id: 34,
        price: 34,
        type: "Tee",
        img: `https://res.cloudinary.com/habitatsartorial/image/upload/${productsImageManip}/v1694108424/bcfc23/317habitat1._001_lqhsce.jpg`,
        images: [
          "https://res.cloudinary.com/habitatsartorial/image/upload/v1694108424/bcfc23/317habitat1._001_lqhsce.jpg",
          "https://res.cloudinary.com/habitatsartorial/image/upload/v1694108425/bcfc23/317habitat1._005_derw1z.jpg",
        ],
        description: [
          "Watercolor Peter CottonTale logo across chest",
          "100% 11.5 oz American grown SUPIMA cotton",
          "Cut and sewn in Portugal",
          "Oversized fit",
          "Color: Cream",
          "This product is painted in watercolor by hand, and each piece is one-of-one with slight variations.",
          "",
          "Half the profits from sales of this tee will go to Peter, and half to our selected Chicago Charities.",
          "",
        ],
        name: "Peter CottonTale Tee",
        disabled: false,
      },
      {
        id: 35,
        price: 34,
        type: "Tee",
        img: `https://res.cloudinary.com/habitatsartorial/image/upload/${productsImageManip}/v1694108424/bcfc23/317habitat1._002_pwoxpp.jpg`,
        images: [
          "https://res.cloudinary.com/habitatsartorial/image/upload/v1694108424/bcfc23/317habitat1._002_pwoxpp.jpg",
          "https://res.cloudinary.com/habitatsartorial/image/upload/v1694108425/bcfc23/317habitat1._005_derw1z.jpg",
        ],
        description: [
          "Watercolor Nico Segal logo across chest",
          "100% 11.5 oz American grown SUPIMA cotton",
          "Cut and sewn in Portugal",
          "Oversized fit",
          "Color: Cream",
          "This product is painted in watercolor by hand, and each piece is one-of-one with slight variations.",
          "",
          "Half the profits from sales of this tee will go to Nico, and half to our selected Chicago Charities.",
          "",
        ],
        name: "Nico Segal Tee",
        disabled: false,
      },
      {
        id: 36,
        price: 34,
        type: "Tee",
        img: `https://res.cloudinary.com/habitatsartorial/image/upload/${productsImageManip}/v1694108424/bcfc23/317habitat1._003_j9ihwl.jpg`,
        images: [
          "https://res.cloudinary.com/habitatsartorial/image/upload/v1694108424/bcfc23/317habitat1._003_j9ihwl.jpg",
          "https://res.cloudinary.com/habitatsartorial/image/upload/v1694108425/bcfc23/317habitat1._005_derw1z.jpg",
        ],
        description: [
          "Watercolor Shawnee Dez logo across chest",
          "100% 11.5 oz American grown SUPIMA cotton",
          "Cut and sewn in Portugal",
          "Oversized fit",
          "Color: Cream",
          "This product is painted in watercolor by hand, and each piece is one-of-one with slight variations.",
          "",
          "Half the profits from sales of this tee will go to Shawnee, and half to our selected Chicago Charities.",
          "",
        ],
        name: "Shawnee Dez Tee",
        disabled: false,
      },
      {
        id: 37,
        price: 34,
        type: "Tee",
        img: `https://res.cloudinary.com/habitatsartorial/image/upload/${productsImageManip}/v1694108498/bcfc23/317habitat2._004_yk15bd.jpg`,
        images: [
          "https://res.cloudinary.com/habitatsartorial/image/upload/v1694108498/bcfc23/317habitat2._004_yk15bd.jpg",
          "https://res.cloudinary.com/habitatsartorial/image/upload/v1694108425/bcfc23/317habitat1._005_derw1z.jpg",
        ],
        description: [
          "Watercolor SheGo Turbo logo across chest",
          "100% 11.5 oz American grown SUPIMA cotton",
          "Cut and sewn in Portugal",
          "Oversized fit",
          "Color: Cream",
          "This product is painted in watercolor by hand, and each piece is one-of-one with slight variations.",
          "",
          "Half the profits from sales of this tee will go to SheGo Turbo, and half to our selected Chicago Charities.",
          "",
        ],
        name: "SheGo Turbo Tee",
        disabled: false,
      },
    ],
  },
  {
    id: "bifi-23",
    name: "Artist Apparel: By Indy, For Indy 2023",
    img: "https://res.cloudinary.com/habitatsartorial/image/upload/v1688578611/bifi23/IMG_3960090DF490-1_hiwgwr.jpg",
    description: "Artist Apparel: By Indy, For Indy 2023.",
    altLink: "/content/bifi23-photos",
    products: [
      {
        id: 30,
        price: 34,
        type: "Tee",
        img: `https://res.cloudinary.com/habitatsartorial/image/upload/${productsImageManip}/v1688402976/bifi23/IMG_2471_orixcy.jpg`,
        images: [
          "https://res.cloudinary.com/habitatsartorial/image/upload/v1688402976/bifi23/IMG_2471_orixcy.jpg",
          "https://res.cloudinary.com/habitatsartorial/image/upload/v1688402976/bifi23/IMG_2462_slohoi.jpg",
          "https://res.cloudinary.com/habitatsartorial/image/upload/v1688403468/bifi23/317habitat._004_rgkfg0.jpg",
          "https://res.cloudinary.com/habitatsartorial/image/upload/v1688403399/bifi23/317habitat._005_wqguv8.jpg",
        ],
        description: [
          "Watercolor midwxst logo across chest",
          "100% 11.5 oz American grown SUPIMA cotton",
          "Cut and sewn in Portugal",
          "Oversized fit",
          "Color: Cream",
          "This product is painted in watercolor by hand, and each piece is one-of-one with slight variations.",
          "",
          "Half the profits from sales of this tee will go to midwxst, and half to the Indianapolis charity of his choice.",
          "Each By Indy, For Indy 2023 Artist Apparel purchase comes with a code for 10% off tickets to the show.",
          "",
        ],
        name: "midwxst Tee",
        disabled: false,
      },
      {
        id: 31,
        price: 34,
        type: "Tee",
        img: `https://res.cloudinary.com/habitatsartorial/image/upload/${productsImageManip}/v1688692020/bifi23/317habitat21354._001_o9ktkk.jpg`,
        images: [
          "https://res.cloudinary.com/habitatsartorial/image/upload/v1688403469/bifi23/317habitat._002_j4e6k5.jpg",
          "https://res.cloudinary.com/habitatsartorial/image/upload/v1688403399/bifi23/317habitat._005_wqguv8.jpg",
        ],
        description: [
          "Watercolor TopView logo across chest",
          "100% 11.5 oz American grown SUPIMA cotton",
          "Cut and sewn in Portugal",
          "Oversized fit",
          "Color: Cream",
          "This product is painted in watercolor by hand, and each piece is one-of-one with slight variations.",
          "",
          "Half the profits from sales of this tee will go to TopView, and half to the Indianapolis charity of their artists' choice.",
          "Each By Indy, For Indy 2023 Artist Apparel purchase comes with a code for 10% off tickets to the show.",
          "",
        ],
        name: "TopView Tee",
        disabled: false,
      },
      {
        id: 32,
        price: 34,
        type: "Tee",
        img: `https://res.cloudinary.com/habitatsartorial/image/upload/${productsImageManip}/v1688402980/bifi23/_DSC0327_rw7iey.jpg`,
        images: [
          "https://res.cloudinary.com/habitatsartorial/image/upload/v1688402980/bifi23/_DSC0327_rw7iey.jpg",
          "https://res.cloudinary.com/habitatsartorial/image/upload/v1688402975/bifi23/_DSC0257_tnsxe7.jpg",
          "https://res.cloudinary.com/habitatsartorial/image/upload/v1688402983/bifi23/_DSC0284_kircoy.jpg",
          "https://res.cloudinary.com/habitatsartorial/image/upload/v1688403475/bifi23/317habitat._003_gqjlxx.jpg",
          "https://res.cloudinary.com/habitatsartorial/image/upload/v1688403399/bifi23/317habitat._005_wqguv8.jpg",
        ],
        description: [
          "Watercolor Henryd logo across chest",
          "100% 11.5 oz American grown SUPIMA cotton",
          "Cut and sewn in Portugal",
          "Oversized fit",
          "Color: Cream",
          "This product is painted in watercolor by hand, and each piece is one-of-one with slight variations.",
          "",
          "Half the profits from sales of this tee will go to Henryd, and half to the Indianapolis charity of his choice.",
          "Each By Indy, For Indy 2023 Artist Apparel purchase comes with a code for 10% off tickets to the show.",
          "",
        ],
        name: "Henryd Tee",
        disabled: false,
      },
      {
        id: 33,
        price: 34,
        type: "Tee",
        img: `https://res.cloudinary.com/habitatsartorial/image/upload/${productsImageManip}/v1688402979/bifi23/IMG_3722_qkcfy5.jpg`,
        images: [
          "https://res.cloudinary.com/habitatsartorial/image/upload/v1688402979/bifi23/IMG_3722_qkcfy5.jpg",
          "https://res.cloudinary.com/habitatsartorial/image/upload/v1688402980/bifi23/IMG_3761_re1gcq.jpg",
          "https://res.cloudinary.com/habitatsartorial/image/upload/v1688403391/bifi23/317habitat._001_qy1eta.jpg",
          "https://res.cloudinary.com/habitatsartorial/image/upload/v1688403399/bifi23/317habitat._005_wqguv8.jpg",
        ],
        description: [
          "Watercolor Arboretum logo across chest",
          "100% 11.5 oz American grown SUPIMA cotton",
          "Cut and sewn in Portugal",
          "Oversized fit",
          "Color: Cream",
          "This product is painted in watercolor by hand, and each piece is one-of-one with slight variations.",
          "",
          "Half the profits from sales of this tee will go to Arboretum, and half to the Indianapolis charity of their choice.",
          "Each By Indy, For Indy 2023 Artist Apparel purchase comes with a code for 10% off tickets to the show.",
          "",
        ],
        name: "Arboretum Tee",
        disabled: false,
      },
    ],
  },
  {
    id: "earth-day-22",
    name: "Earth Day 2022",
    img: "https://res.cloudinary.com/habitatsartorial/image/upload/v1650418843/2022/Earth%20Day/Lookbook/DSC09921_bh7spy.jpg",
    description: "Description 1",
    altLink: "/content/earthday-22-lookbook",
    products: [
      {
        id: 15,
        price: 33,
        type: "Tee",
        img: `https://res.cloudinary.com/habitatsartorial/image/upload/${productsImageManip}/v1650418831/2022/Earth%20Day/Lookbook/DSC00243_xi9eyp.jpg`,
        images: [
          "https://res.cloudinary.com/habitatsartorial/image/upload/v1650418831/2022/Earth%20Day/Lookbook/DSC00243_xi9eyp.jpg",
          "https://res.cloudinary.com/habitatsartorial/image/upload/v1650422875/2022/Earth%20Day/Product%20Shots/DSC00268_pbfzth.jpg",
          "https://res.cloudinary.com/habitatsartorial/image/upload/v1650422875/2022/Earth%20Day/Product%20Shots/DSC00267_sux8y4.jpg",
          "https://res.cloudinary.com/habitatsartorial/image/upload/v1650422876/2022/Earth%20Day/Product%20Shots/DSC00283_t6i7wx.jpg",
          "https://res.cloudinary.com/habitatsartorial/image/upload/v1650422875/2022/Earth%20Day/Product%20Shots/DSC00286_y41hmi.jpg",
        ],
        description: [
          'Mixed Paint "HA317AT" Logo at center of chest',
          'Watercolor "For our city, for our Planet" Logo across back',
          "100% 6 oz American grown SUPIMA cotton",
          "Preshrunk, order to size",
          "Color: Natural",
          "",
          "This product is painted in watercolor by hand, and each piece is one-of-one with slight variations.",
          "This product is certified 100% organic by the Global Organic Textile Standard, license no. USB TEX 4248.",
          "",
          "Profits from sales of this tee will be donated to Horizon House Indy.",
        ],
        name: "Earth Day Tee",
        disabled: false,
      },

      {
        id: 16,
        price: 37,
        type: "LS Tee",
        img: `https://res.cloudinary.com/habitatsartorial/image/upload/${productsImageManip}/v1650418849/2022/Earth%20Day/Lookbook/DSC00301_xqptwh.jpg`,
        images: [
          "https://res.cloudinary.com/habitatsartorial/image/upload/v1650418849/2022/Earth%20Day/Lookbook/DSC00301_xqptwh.jpg",
          "https://res.cloudinary.com/habitatsartorial/image/upload/v1650422880/2022/Earth%20Day/Product%20Shots/DSC00275_gtgd3a.jpg",
          "https://res.cloudinary.com/habitatsartorial/image/upload/v1650422875/2022/Earth%20Day/Product%20Shots/DSC00276_ou12mu.jpg",
          "https://res.cloudinary.com/habitatsartorial/image/upload/v1650422880/2022/Earth%20Day/Product%20Shots/DSC00272_imbkcj.jpg",
        ],
        description: [
          'Mixed Paint "HA317AT" Logo at center of chest',
          'Watercolor "For our city, for our Planet" Logo across back',
          "100% 6 oz American grown SUPIMA cotton",
          "Preshrunk, order to size",
          "Color: Natural",
          "",
          "This product is painted in watercolor by hand, and each piece is one-of-one with slight variations.",
          "This product is certified 100% organic by the Global Organic Textile Standard, license no. USB TEX 4248.",
          "",
          "Profits from sales of this tee will be donated to Horizon House Indy.",
        ],
        name: "Earth Day LS Tee",
        disabled: false,
      },

      {
        id: 17,
        price: 47,
        type: "Crewneck Sweatshirt",
        img: `https://res.cloudinary.com/habitatsartorial/image/upload/${productsImageManip}/v1650418836/2022/Earth%20Day/Lookbook/DSC00555_pmn7x0.jpg`,
        images: [
          "https://res.cloudinary.com/habitatsartorial/image/upload/v1650418836/2022/Earth%20Day/Lookbook/DSC00555_pmn7x0.jpg",
          "https://res.cloudinary.com/habitatsartorial/image/upload/v1650422880/2022/Earth%20Day/Product%20Shots/DSC00296_ndmos2.jpg",
          "https://res.cloudinary.com/habitatsartorial/image/upload/v1650422875/2022/Earth%20Day/Product%20Shots/DSC00290_xw9pje.jpg",
          "https://res.cloudinary.com/habitatsartorial/image/upload/v1650422880/2022/Earth%20Day/Product%20Shots/DSC00298_os4ebo.jpg",
        ],
        description: [
          'Mixed Paint "HA317AT" Logo at center of chest',
          'Watercolor "For our city, for our Planet" Logo across back',
          "100% 400gsm heavyweight cotton",
          "Preshrunk, order to size",
          "Color: Natural",
          "",
          "This product is painted in watercolor by hand, and each piece is one-of-one with slight variations.",
          "This product is certified 100% organic by the Global Organic Textile Standard, license no. USB TEX 4248.",
          "",
          "Profits from sales of this sweatshirt will be donated to Horizon House Indy.",
        ],
        name: "Earth Day Sweatshirt",
        disabled: false,
      },
    ],
  },
  {
    id: "fw21-classics-2",
    name: "Fall/Winter 2021 Classics 2",
    img: "https://res.cloudinary.com/habitatsartorial/image/upload/c_fit,g_auto:faces/v1638227214/Fall%20Winter%202021%20Classics%202%20Lookbook/IMG_3383_ztfzbc.jpg",
    description: "Description 1",
    altLink: "/content/fw21-classics-2-bts",
    products: [
      {
        id: 7,
        price: 23,
        type: "Tee",
        img: `https://res.cloudinary.com/habitatsartorial/image/upload/${productsImageManip}/v1638767814/Fall%20Winter%202020%20Classics%202%20Product%20Photos/IMG_3680_yzef2c.jpg`,
        images: [
          "https://res.cloudinary.com/habitatsartorial/image/upload/v1638767814/Fall%20Winter%202020%20Classics%202%20Product%20Photos/IMG_3680_yzef2c.jpg",
          "https://res.cloudinary.com/habitatsartorial/image/upload/v1638767814/Fall%20Winter%202020%20Classics%202%20Product%20Photos/IMG_3683_xxlu8b.jpg",
          "https://res.cloudinary.com/habitatsartorial/image/upload/v1638770459/Fall%20Winter%202020%20Classics%202%20Product%20Photos/Closeups/IMG_1712_ipi80l.jpg",
          "https://res.cloudinary.com/habitatsartorial/image/upload/v1638770463/Fall%20Winter%202020%20Classics%202%20Product%20Photos/Closeups/IMG_1714_gbmwu7.jpg",
        ],
        description: [
          '"HA317AT" Logo at top of chest',
          '"Homegrown" Logo across back',
          "100% 6.1oz ringspun cotton",
          "Preshrunk, soft-washed, American Made",
          "Color: Forest green",
          "",
          "All profits from sales of this tee will be donated to Horizon House Indy.",
        ],
        name: '"Homegrown" Tee',
        disabled: false,
      },

      {
        id: 8,
        price: 25,
        type: "LS Tee",
        img: `https://res.cloudinary.com/habitatsartorial/image/upload/${productsImageManip}/v1638227216/Fall%20Winter%202021%20Classics%202%20Lookbook/IMG_3351_uvt1ya.jpg`,
        images: [
          "https://res.cloudinary.com/habitatsartorial/image/upload/v1638767813/Fall%20Winter%202020%20Classics%202%20Product%20Photos/IMG_3684_nxc4wf.jpg",
          "https://res.cloudinary.com/habitatsartorial/image/upload/v1638767812/Fall%20Winter%202020%20Classics%202%20Product%20Photos/IMG_3687_vpytm4.jpg",
          "https://res.cloudinary.com/habitatsartorial/image/upload/v1638770459/Fall%20Winter%202020%20Classics%202%20Product%20Photos/Closeups/IMG_1712_ipi80l.jpg",
          "https://res.cloudinary.com/habitatsartorial/image/upload/v1638770463/Fall%20Winter%202020%20Classics%202%20Product%20Photos/Closeups/IMG_1714_gbmwu7.jpg",
          "https://res.cloudinary.com/habitatsartorial/image/upload/v1638227216/Fall%20Winter%202021%20Classics%202%20Lookbook/IMG_3351_uvt1ya.jpg",
        ],
        description: [
          '"HA317AT" Logo at top of chest',
          '"Homegrown" Logo across back',
          "100% 6.1oz ringspun cotton",
          "Preshrunk, soft-washed, American Made",
          "Color: Forest green",
          "",
          "All profits from sales of this tee will be donated to Horizon House Indy.",
        ],
        name: '"Homegrown" LS Tee',
        disabled: false,
      },

      {
        id: 9,
        price: 23,
        type: "Cropped Tee",
        img: `https://res.cloudinary.com/habitatsartorial/image/upload/${productsImageManip}/v1638227215/Fall%20Winter%202021%20Classics%202%20Lookbook/IMG_3338_wv2ild.jpg`,
        images: [
          "https://res.cloudinary.com/habitatsartorial/image/upload/v1638767814/Fall%20Winter%202020%20Classics%202%20Product%20Photos/IMG_3714_2_pgysw7.jpg",
          "https://res.cloudinary.com/habitatsartorial/image/upload/v1638767816/Fall%20Winter%202020%20Classics%202%20Product%20Photos/IMG_3716_euxfhm.jpg",
          "https://res.cloudinary.com/habitatsartorial/image/upload/v1638770462/Fall%20Winter%202020%20Classics%202%20Product%20Photos/Closeups/IMG_1715_fxesg1.jpg",
          "https://res.cloudinary.com/habitatsartorial/image/upload/v1638227215/Fall%20Winter%202021%20Classics%202%20Lookbook/IMG_3338_wv2ild.jpg",
        ],
        description: [
          '"HA317AT" Logo at top of chest',
          '"Homegrown" Logo across back',
          "8.5 inches from underarm to raw-cut hem",
          "100% 6.1oz ringspun cotton",
          "Preshrunk, soft-washed, American Made",
          "Color: Black",
          "",
          "All profits from sales of this tee will be donated to Horizon House Indy.",
        ],
        name: 'Cropped "Homegrown" Tee',
        disabled: false,
      },

      {
        id: 10,
        price: 25,
        type: "Cropped LS Tee",
        img: `https://res.cloudinary.com/habitatsartorial/image/upload/${productsImageManip}/v1638227219/Fall%20Winter%202021%20Classics%202%20Lookbook/IMG_3465_egi2be.jpg`,
        images: [
          "https://res.cloudinary.com/habitatsartorial/image/upload/v1638767817/Fall%20Winter%202020%20Classics%202%20Product%20Photos/IMG_3718_ok66qe.jpg",
          "https://res.cloudinary.com/habitatsartorial/image/upload/v1638767818/Fall%20Winter%202020%20Classics%202%20Product%20Photos/IMG_3720_ab6e0p.jpg",
          "https://res.cloudinary.com/habitatsartorial/image/upload/v1638770462/Fall%20Winter%202020%20Classics%202%20Product%20Photos/Closeups/IMG_1715_fxesg1.jpg",
          "https://res.cloudinary.com/habitatsartorial/image/upload/v1638227219/Fall%20Winter%202021%20Classics%202%20Lookbook/IMG_3465_egi2be.jpg",
        ],
        description: [
          '"HA317AT" Logo at top of chest',
          '"Homegrown" Logo across back',
          "8.5 inches from underarm to raw-cut hem",
          "100% 6.1oz ringspun cotton",
          "Preshrunk, soft-washed, American Made",
          "Color: Black",
          "",
          "All profits from sales of this tee will be donated to Horizon House Indy.",
        ],
        name: 'Cropped "Homegrown" LS Tee',
        disabled: false,
      },

      {
        id: 11,
        price: 42,
        type: "Hoodie",
        img: `https://res.cloudinary.com/habitatsartorial/image/upload/${productsImageManip}/v1638767818/Fall%20Winter%202020%20Classics%202%20Product%20Photos/IMG_3728_e6vnbf.jpg`,
        images: [
          "https://res.cloudinary.com/habitatsartorial/image/upload/v1638227219/Fall%20Winter%202021%20Classics%202%20Lookbook/IMG_3536_w2b0qs.jpg",
          "https://res.cloudinary.com/habitatsartorial/image/upload/v1638767818/Fall%20Winter%202020%20Classics%202%20Product%20Photos/IMG_3728_e6vnbf.jpg",
          "https://res.cloudinary.com/habitatsartorial/image/upload/v1638767812/Fall%20Winter%202020%20Classics%202%20Product%20Photos/IMG_3707_lalcsb.jpg",
          "https://res.cloudinary.com/habitatsartorial/image/upload/v1638770461/Fall%20Winter%202020%20Classics%202%20Product%20Photos/Closeups/IMG_1702_peae5v.jpg",
          "https://res.cloudinary.com/habitatsartorial/image/upload/v1638770463/Fall%20Winter%202020%20Classics%202%20Product%20Photos/Closeups/IMG_1701_uqxg3n.jpg",
        ],
        description: [
          '"HA317AT" Logo at center of chest',
          "8.5 oz. 80/20 cotton poly blend",
          "Metal Eyelets, Drawstring, Front Pocket",
          "Color: Camel",
          "",
          "All profits from sales of this sweatshirt will be donated to Horizon House Indy.",
        ],
        name: '"HA317AT" Hooded Sweatshirt',
        disabled: false,
      },

      {
        id: 12,
        price: 42,
        type: "Crewneck",
        img: `https://res.cloudinary.com/habitatsartorial/image/upload/${productsImageManip}/v1638227216/Fall%20Winter%202021%20Classics%202%20Lookbook/IMG_3336_psoxgq.jpg`,
        images: [
          "https://res.cloudinary.com/habitatsartorial/image/upload/v1638767812/Fall%20Winter%202020%20Classics%202%20Product%20Photos/IMG_3710_rniwzr.jpg",
          "https://res.cloudinary.com/habitatsartorial/image/upload/v1638767818/Fall%20Winter%202020%20Classics%202%20Product%20Photos/IMG_3730_mo1v6v.jpg",
          "https://res.cloudinary.com/habitatsartorial/image/upload/v1638770460/Fall%20Winter%202020%20Classics%202%20Product%20Photos/Closeups/IMG_1704_mnsstz.jpg",
          "https://res.cloudinary.com/habitatsartorial/image/upload/v1638770460/Fall%20Winter%202020%20Classics%202%20Product%20Photos/Closeups/IMG_1700_ozgchc.jpg",
          "https://res.cloudinary.com/habitatsartorial/image/upload/v1638227216/Fall%20Winter%202021%20Classics%202%20Lookbook/IMG_3336_psoxgq.jpg",
        ],
        description: [
          'Combo "100" and "HA317AT" Logo at center of chest',
          "8.5 oz. 80/20 cotton poly blend",
          "Color: Camel",
          "",
          "All profits from sales of this sweatshirt will be donated to Horizon House Indy.",
        ],
        name: "Combo Crewneck Sweatshirt",
        disabled: false,
      },

      {
        id: 13,
        price: 37,
        type: "Sweatshorts",
        img: `https://res.cloudinary.com/habitatsartorial/image/upload/${productsImageManip}/v1638227219/Fall%20Winter%202021%20Classics%202%20Lookbook/IMG_3536_w2b0qs.jpg`,
        images: [
          "https://res.cloudinary.com/habitatsartorial/image/upload/v1638767815/Fall%20Winter%202020%20Classics%202%20Product%20Photos/IMG_3698_twul1x.jpg",
          "https://res.cloudinary.com/habitatsartorial/image/upload/v1638767817/Fall%20Winter%202020%20Classics%202%20Product%20Photos/IMG_3726_d9ms5e.jpg",
          "https://res.cloudinary.com/habitatsartorial/image/upload/v1638770464/Fall%20Winter%202020%20Classics%202%20Product%20Photos/Closeups/IMG_1705_fwzjic.jpg",
          "https://res.cloudinary.com/habitatsartorial/image/upload/v1638770462/Fall%20Winter%202020%20Classics%202%20Product%20Photos/Closeups/IMG_1708_qhcr79.jpg",
          "https://res.cloudinary.com/habitatsartorial/image/upload/v1638227219/Fall%20Winter%202021%20Classics%202%20Lookbook/IMG_3536_w2b0qs.jpg",
        ],
        description: [
          "Back by popular demand, with new colors",
          '"HA317AT" logo across front',
          "8.5 oz. 80/20 cotton poly blend",
          "Elastic waistband with drawcord",
          "Back and side pockets",
          "Raw-cut hem, custom inseam",
          "Color: Black",
          "",
          "All profits from sales of these sweatshorts will be donated to Horizon House Indy.",
        ],
        name: '"HA317AT" Sweatshorts',
        disabled: false,
      },

      {
        id: 14,
        price: 42,
        type: "Sweatpants",
        img: `https://res.cloudinary.com/habitatsartorial/image/upload/${productsImageManip}/v1638227218/Fall%20Winter%202021%20Classics%202%20Lookbook/IMG_3363_sgf1uu.jpg`,
        images: [
          "https://res.cloudinary.com/habitatsartorial/image/upload/v1638767818/Fall%20Winter%202020%20Classics%202%20Product%20Photos/IMG_3722_g2b6ld.jpg",
          "https://res.cloudinary.com/habitatsartorial/image/upload/v1638767816/Fall%20Winter%202020%20Classics%202%20Product%20Photos/IMG_3688_kj1jza.jpg",
          "https://res.cloudinary.com/habitatsartorial/image/upload/v1638770456/Fall%20Winter%202020%20Classics%202%20Product%20Photos/Closeups/IMG_1709_x61qzs.jpg",
          "https://res.cloudinary.com/habitatsartorial/image/upload/v1638770459/Fall%20Winter%202020%20Classics%202%20Product%20Photos/Closeups/IMG_1710_ltzrmk.jpg",

          "https://res.cloudinary.com/habitatsartorial/image/upload/v1638227218/Fall%20Winter%202021%20Classics%202%20Lookbook/IMG_3363_sgf1uu.jpg",
        ],
        description: [
          "Rotary logo on outside of left leg",
          "8.5 oz. 80/20 cotton poly blend",
          "Elastic waistband with drawcord",
          "Tapered fit with cuffed ankles",
          "Back and side pockets",
          "Color: Black",
          "",
          "All profits from sales of these sweatpants will be donated to Horizon House Indy.",
        ],
        name: "Rotary Sweatpants",
        disabled: false,
      },
    ],
  },
];

const collectionRelease3 = [
  {
    id: "anywhere",
    name: "(anywhere)",
    img: "https://res.cloudinary.com/habitatsartorial/image/upload/v1713886785/%28anywhere%29/%28anywhere%29i/anywhere_coverii_glmdhn.jpg",
    description: "Description 1",
    altLink: "/anywhere",
    products: [
      {
        id: 125,
        price: 29,
        type: "LS Tee",
        img: `https://res.cloudinary.com/habitatsartorial/image/upload/${productsImageManip}/v1713884269/%28anywhere%29/%28anywhere%29i/anywhere_product_shots._003_zv4cuo.jpg`,
        images: [
          "https://res.cloudinary.com/habitatsartorial/image/upload/v1705605201/%28anywhere%29/%28anywhere%29i/1W3A2757_zvqcl3.jpg",
          "https://res.cloudinary.com/habitatsartorial/image/upload/v1705605242/%28anywhere%29/%28anywhere%29i/1W3A2802_cptt2d.jpg",
          "https://res.cloudinary.com/habitatsartorial/image/upload/v1713884269/%28anywhere%29/%28anywhere%29i/anywhere_product_shots._003_zv4cuo.jpg",
          "https://res.cloudinary.com/habitatsartorial/image/upload/v1713884288/%28anywhere%29/%28anywhere%29i/anywhere_product_shots._004_r8ddxj.jpg",
        ],
        description: [
          '"Liwa Oasis" graphic with arabic label across chest',
          '"charity you can wear (anywhere)" logo at base of neck',
          "100% ringspun cotton, 13oz",
          "Preshrunk, soft-washed, garment dyed fabric",
          "Color: Desert",
          "",
          "All (anywhere) apparel can be customized to any color!",
          "If you'd like a different color than pictured here, place an order, then send",
          "an email to payne@habitatsartorial.org with your color preference, and we'll make it for you!",
          "",
          "All profits from sales of (anywhere) apparel go to Habitat Sartorial's (anywhere) fund.",
          "This fund supports existing international projects and those we are working on behind the scenes.",
          "",
        ],
        name: '"Liwa Oasis" LS Tee',
        disabled: false,
      },

      {
        id: 126,
        price: 44,
        type: "Hoodie",
        img: `https://res.cloudinary.com/habitatsartorial/image/upload/${productsImageManip}/v1713884363/%28anywhere%29/%28anywhere%29i/anywhere_product_shots._005_ghnhql.jpg`,
        images: [
          "https://res.cloudinary.com/habitatsartorial/image/upload/v1705605243/%28anywhere%29/%28anywhere%29i/1W3A3101_ky522a.jpg",
          "https://res.cloudinary.com/habitatsartorial/image/upload/v1713884363/%28anywhere%29/%28anywhere%29i/anywhere_product_shots._005_ghnhql.jpg",
          "https://res.cloudinary.com/habitatsartorial/image/upload/v1713884358/%28anywhere%29/%28anywhere%29i/anywhere_product_shots._006_uznimx.jpg",

          "https://res.cloudinary.com/habitatsartorial/image/upload/v1705605197/%28anywhere%29/%28anywhere%29i/1W3A2735_xcvj3u.jpg",
        ],
        description: [
          '"Elk Range" graphic at center of chest',
          '"charity you can wear (anywhere) logo on back of hood',
          "Preshrunk, soft-washed, garment dyed fabric, brushed interior",
          "Color: Conifer",
          "",
          "All (anywhere) apparel can be customized to any color!",
          "If you'd like a different color than pictured here, place an order, then send",
          "an email to payne@habitatsartorial.org with your color preference, and we'll make it for you!",
          "",
          "All profits from sales of (anywhere) apparel go to Habitat Sartorial's (anywhere) fund.",
          "This fund supports existing international projects and those we are working on behind the scenes.",
          "",
        ],
        name: '"Elk Range" Hoodie',
        disabled: false,
      },

      {
        id: 127,
        price: 34,
        type: "Sweatshort",
        img: `https://res.cloudinary.com/habitatsartorial/image/upload/${productsImageManip}/v1713885301/%28anywhere%29/%28anywhere%29i/anywhere_product_shots._011_u5nk7e.jpg`,
        images: [
          "https://res.cloudinary.com/habitatsartorial/image/upload/v1705605219/%28anywhere%29/%28anywhere%29i/1W3A3236_l82cdu.jpg",
          "https://res.cloudinary.com/habitatsartorial/image/upload/v1713885301/%28anywhere%29/%28anywhere%29i/anywhere_product_shots._011_u5nk7e.jpg",
          "https://res.cloudinary.com/habitatsartorial/image/upload/v1713885294/%28anywhere%29/%28anywhere%29i/anywhere_product_shots._012_p4i76p.jpg",
        ],
        description: [
          '"HA317AT" logo across front',
          "100% Organic Cotton loopback French terry, 16oz",
          "Color: Desert",
          "",
          "All (anywhere) apparel can be customized to any color!",
          "If you'd like a different color than pictured here, place an order, then send",
          "an email to payne@habitatsartorial.org with your color preference, and we'll make it for you!",
          "",
          "All profits from sales of (anywhere) apparel go to Habitat Sartorial's (anywhere) fund.",
          "This fund supports existing international projects and those we are working on behind the scenes.",
          "",
        ],
        name: '"HA317AT" Sweatshort',
        disabled: false,
      },

      {
        id: 128,
        price: 39,
        type: "Sweatpant",
        img: `https://res.cloudinary.com/habitatsartorial/image/upload/${productsImageManip}/v1713885252/%28anywhere%29/%28anywhere%29i/anywhere_product_shots._013_ijedcp.jpg`,
        images: [
          "https://res.cloudinary.com/habitatsartorial/image/upload/v1705605201/%28anywhere%29/%28anywhere%29i/1W3A2757_zvqcl3.jpg",
          "https://res.cloudinary.com/habitatsartorial/image/upload/v1713885252/%28anywhere%29/%28anywhere%29i/anywhere_product_shots._013_ijedcp.jpg",
          "https://res.cloudinary.com/habitatsartorial/image/upload/v1713885297/%28anywhere%29/%28anywhere%29i/anywhere_product_shots._014_xpylgt.jpg",
        ],
        description: [
          '"Catarata del Gocta" graphic on below left front pocket',
          '"charity you can wear (anywhere) logo at right knee',
          "100% Organic Cotton double-folded, flat-knit cotton ribbing",
          "Color: Lavender",
          "",
          "All (anywhere) apparel can be customized to any color!",
          "If you'd like a different color than pictured here, place an order, then send",
          "an email to payne@habitatsartorial.org with your color preference, and we'll make it for you!",
          "",
          "All profits from sales of (anywhere) apparel go to Habitat Sartorial's (anywhere) fund.",
          "This fund supports existing international projects and those we are working on behind the scenes.",
          "",
        ],
        name: '"Catarata del Gocta" Sweatpant',
        disabled: false,
      },

      {
        id: 129,
        price: 44,
        type: "Hoodie",
        img: `https://res.cloudinary.com/habitatsartorial/image/upload/${productsImageManip}/v1713886045/%28anywhere%29/%28anywhere%29i/anywhere_product_shots._007_lta08q.jpg`,
        images: [
          "https://res.cloudinary.com/habitatsartorial/image/upload/v1705605219/%28anywhere%29/%28anywhere%29i/1W3A3236_l82cdu.jpg",
          "https://res.cloudinary.com/habitatsartorial/image/upload/v1713886045/%28anywhere%29/%28anywhere%29i/anywhere_product_shots._007_lta08q.jpg",

          "https://res.cloudinary.com/habitatsartorial/image/upload/v1713886046/%28anywhere%29/%28anywhere%29i/anywhere_product_shots._008_vlj32n.jpg",
        ],
        description: [
          'Rounded "HA317AT" Logo at center of chest',
          '"charity you can wear (anywhere)" logo on back of hood',
          "100% ringspun cotton, 32oz",
          "Preshrunk, soft-washed, garment dyed fabric, brushed interior",
          "Color: Grey",
          "",
          "All (anywhere) apparel can be customized to any color!",
          "If you'd like a different color than pictured here, place an order, then send",
          "an email to payne@habitatsartorial.org with your color preference, and we'll make it for you!",
          "",
          "All profits from sales of (anywhere) apparel go to Habitat Sartorial's (anywhere) fund.",
          "This fund supports existing international projects and those we are working on behind the scenes.",
          "",
        ],
        name: '"HA317AT" (anywhere) Hoodie',
        disabled: false,
      },

      {
        id: 130,
        price: 34,
        type: "Sweatshort",
        img: `https://res.cloudinary.com/habitatsartorial/image/upload/${productsImageManip}/v1713885160/%28anywhere%29/%28anywhere%29i/anywhere_product_shots._009_c8dooh.jpg`,
        images: [
          "https://res.cloudinary.com/habitatsartorial/image/upload/v1705605224/%28anywhere%29/%28anywhere%29i/1W3A3104_izupwf.jpg",
          "https://res.cloudinary.com/habitatsartorial/image/upload/v1713885160/%28anywhere%29/%28anywhere%29i/anywhere_product_shots._009_c8dooh.jpg",
          "https://res.cloudinary.com/habitatsartorial/image/upload/v1713885188/%28anywhere%29/%28anywhere%29i/anywhere_product_shots._010_jkuaun.jpg",
        ],
        description: [
          '"HA317AT SARTORIAL" logo on left front',
          '"charity you can wear" logo on right front',
          "100% Organic Cotton loopback French terry, 16oz",
          "Color: Lavender",
          "",
          "All (anywhere) apparel can be customized to any color!",
          "If you'd like a different color than pictured here, place an order, then send",
          "an email to payne@habitatsartorial.org with your color preference, and we'll make it for you!",
          "",
          "All profits from sales of (anywhere) apparel go to Habitat Sartorial's (anywhere) fund.",
          "This fund supports existing international projects and those we are working on behind the scenes.",
          "",
        ],
        name: '"HA317AT" (anywhere) Sweatshorts',
        disabled: false,
      },

      {
        id: 131,
        price: 39,
        type: "Sweatpants",
        img: `https://res.cloudinary.com/habitatsartorial/image/upload/${productsImageManip}/v1713885323/%28anywhere%29/%28anywhere%29i/anywhere_product_shots._015_on6jgg.jpg`,
        images: [
          "https://res.cloudinary.com/habitatsartorial/image/upload/v1705605197/%28anywhere%29/%28anywhere%29i/1W3A2735_xcvj3u.jpg",
          "https://res.cloudinary.com/habitatsartorial/image/upload/v1705605225/%28anywhere%29/%28anywhere%29i/1W3A3183_bnmpx9.jpg",
          "https://res.cloudinary.com/habitatsartorial/image/upload/v1713885323/%28anywhere%29/%28anywhere%29i/anywhere_product_shots._015_on6jgg.jpg",
          "https://res.cloudinary.com/habitatsartorial/image/upload/v1713885221/%28anywhere%29/%28anywhere%29i/anywhere_product_shots._016_yui40b.jpg",
        ],
        description: [
          '"Maletsunyane" graphic on below left front pocket',
          '"charity you can wear (anywhere) logo at right knee',
          "100% Organic Cotton double-folded, flat-knit cotton ribbing",
          "Color: Natural",
          "",
          "All (anywhere) apparel can be customized to any color!",
          "If you'd like a different color than pictured here, place an order, then send",
          "an email to payne@habitatsartorial.org with your color preference, and we'll make it for you!",
          "",
          "All profits from sales of (anywhere) apparel go to Habitat Sartorial's (anywhere) fund.",
          "This fund supports existing international projects and those we are working on behind the scenes.",
          "",
        ],
        name: '"Maletsunyane" Sweatpant',
        disabled: false,
      },

      {
        id: 132,
        price: 34,
        type: "Crewneck Sweatshirt",
        img: `https://res.cloudinary.com/habitatsartorial/image/upload/${productsImageManip}/v1713884314/%28anywhere%29/%28anywhere%29i/anywhere_product_shots._001_rpx34v.jpg`,
        images: [
          "https://res.cloudinary.com/habitatsartorial/image/upload/v1713884314/%28anywhere%29/%28anywhere%29i/anywhere_product_shots._001_rpx34v.jpg",
          "https://res.cloudinary.com/habitatsartorial/image/upload/v1713884245/%28anywhere%29/%28anywhere%29i/anywhere_product_shots._002_w945zy.jpg",
        ],
        description: [
          '"Palau" graphic across chest',
          '"charity you can wear (anywhere)" logo at base of neck',
          "100% ringspun cotton, 32oz",
          "Preshrunk, soft-washed, garment dyed fabric, brushed interior",
          "Color: Lavender",
          "",
          "All (anywhere) apparel can be customized to any color!",
          "If you'd like a different color than pictured here, place an order, then send",
          "an email to payne@habitatsartorial.org with your color preference, and we'll make it for you!",
          "",
          "All profits from sales of (anywhere) apparel go to Habitat Sartorial's (anywhere) fund.",
          "This fund supports existing international projects and those we are working on behind the scenes.",
          "",
        ],
        name: '"Palau" Sweatshirt',
        disabled: false,
      },
    ],
  },
];

collectionRelease3.push(...collectionRelease2);
collectionRelease3.push(...collectionRelease1);

export default collectionRelease3;
